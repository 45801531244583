import { graphql } from 'gatsby';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import { textStyles } from '../styles/utils';
import { PostContent } from '../types/content';

interface Props {
  pathContext: {
    tag: string;
  };
  data: {
    allMarkdownRemark: {
      edges: {
        node: PostContent;
      }[];
    };
  };
}

const TagPage = ({ data, pathContext }: Props): ReactElement => {
  const { allMarkdownRemark } = data;

  const title = `Posts tagged with ${pathContext.tag}`;
  const description = `See all posts and content related to ${pathContext.tag}`;

  return (
    <Layout>
      <Helmet title={title}>
        <meta name="description" content={description} />
      </Helmet>

      <h1 className={textStyles.center}>{title}</h1>

      <PostList posts={allMarkdownRemark.edges} />
    </Layout>
  );
};

export default TagPage;

export const query = graphql`
  query BlogPostsByTag($tag: String!) {
    allMarkdownRemark(filter: { frontmatter: { tags: { eq: $tag } } }) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            title
            description
            date
            tags
          }
        }
      }
    }
  }
`;
